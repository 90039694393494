import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import {AboutSection, Avatar, Title, Text, SubTitle} from './style';
import {SectionIntro, ContainerLayout, ResumeButton} from "../common";
import AvatarFramed from "../avatar";
import { Link } from "gatsby"


const About = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "dp.webp" }) {
        childImageSharp {
          fluid(maxWidth: 550) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <>
      <SectionIntro>
        <ContainerLayout>
          <AboutSection>
            <div>
              <AvatarFramed />
              {/* <Avatar fluid={data.placeholderImage.childImageSharp.fluid} alt="user photo" /> */}
              {/* <SubTitle> ML Engineer</SubTitle> */}
            </div> 
            <div>
              <Title> Hello & Welcome!</Title>
              <Text>
                I've spent over 7 years building high performance product teams, and human centric products powered by AI.
              </Text>
              <Text>
                This is my online playground- a place to 
                share what I learn from life, work, and everything 
                in between.
              </Text>


              <Text>

                {/* <Link to="/leading-teams">
                 Hire me if we're a good match.
                </Link> */}
              </Text>
              
              
              {/* <ResumeButton href="https://drive.google.com/file/d/1Y810uhBCH5H8SCx0-f-xnLHI1Shm2RhA/view?usp=sharing" target="_blank"> Download my resume </ResumeButton> */}
            </div>
          </AboutSection>
        </ContainerLayout>
      </SectionIntro>
    </>
  )
}


export default About
