// https://codepen.io/sfi0zy/pen/XWWKqWZ
import React from 'react'
import { Avatar } from '../about/style'
import './Avatar.css'


const AvatarFramed = () =>{
    return (    
        <div class="example">
        <div class="block">
            <div class="side -main"></div>
            <div class="side -left"></div>
        </div>
        <div class="block">
            <div class="side -main"></div>
            <div class="side -left"></div>
        </div>
        <div class="block">
            <div class="side -main"></div>
            <div class="side -left"></div>
        </div>
        </div>
    )
}

export default AvatarFramed;